@import "../../../styles/variables.scss";

.input-field {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 328px;
  height: 56px;
  color: rgba($primaryFontColor, 0.5);
  background: rgba($secondaryColor, 0.05) !important;
  padding: 5px 16px;
  border-bottom: 2px solid transparent;

  input {
    color: $primaryFontColor !important;

    &:hover {
      cursor: text;
    }
  }

  .input-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    svg {
      width: 21px;
    }
  }

  .error-message {
    color: $dangerColor;
  }
}

.input-focused {
  border-bottom: 2px solid $primaryLighterColor;
  color: $primaryLighterColor;
}
