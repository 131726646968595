@import "../../../../../styles/variables.scss";

.rdt_Table {
  border-radius: 4px;
  overflow: hidden;

  .rdt_TableRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: $primaryFontColor;
    font-size: 14px;
    background-color: rgba($secondaryColor, 0.05);
  }

  .rdt_TableHeadRow {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  .rdt_TableCol_Sortable {
    display: flex;
    flex-direction: row;
    width: 100%;
    font-weight: 300;

    div:first-child {
      margin-right: 10px;
    }

    .filter-icon {
      width: 14px !important;
    }
  }

  .rdt_TableCell {
    padding: 17px 16px;
    font-weight: 300;

    &:last-child {
      display: flex;
      align-items: center;
    }
  }

  svg {
    opacity: 1 !important;
  }

  .edit-button {
    width: 24px;
    height: 24px;
    padding: 4px 10px;
    align-items: center;

    svg {
      width: 4px;
    }
  }
}
