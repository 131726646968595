$primaryColor: #1e3ab0;
$secondaryColor: #1e1e1e;
$tertiaryColor: #ffcf03;
$tertiaryDarkColor: #f2c400;
$contrastColor: #fff;

$primaryDarkerColor: #193192;
$primaryLighterColor: #2b4dd6;

$primaryFontColor: #424242;
$contrastFontColor: #ffffff;

$dangerColor: red;

$headerSize: 67px;
