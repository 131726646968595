@import "../../../styles/variables.scss";

$loaderWidth: 50px;
$loaderHeight: 50px;
$loaderColor: $primaryLighterColor;
$loaderSize: 3px;
$loaderTiming: 1.3s;

.loader {
  width: $loaderWidth;
  height: $loaderHeight;
  border-radius: calc(#{$loaderWidth} / 2);
  border-top: $loaderSize solid $loaderColor;
  animation: rotate-loader $loaderTiming linear infinite;
  margin: auto;
}

@keyframes rotate-loader {
  to {
    transform: rotate(360deg);
  }
}
