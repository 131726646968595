@import "../../../styles/variables.scss";

.login {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;

  .login-container {
    margin: auto;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5rem;

    svg {
      height: 100%;
    }

    .centered-container {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }

    .account-content {
      background-color: $contrastColor;
      box-shadow: 0 8px 10px -5px rgba(0, 0, 0, 0.2), 0 6px 30px 5px rgba(0, 0, 0, 0.12),
        0 16px 24px 2px rgba(0, 0, 0, 0.14);
      width: 920px;
      height: 600px;
      border-radius: 4px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: auto;

      h1 {
        font-size: 34px;
        margin-bottom: 56px;
        color: $primaryDarkerColor;

        font-size: 2.25rem;
        font-weight: 800;
        text-transform: uppercase;
      }

      .link-forgot-password {
        color: rgba($primaryFontColor, 0.5);
        margin-bottom: 64px;
        font-size: 14px;
        align-self: flex-start;
        text-decoration: none;
        cursor: pointer;
        font-weight: 300;

        &:hover {
          color: rgba($primaryFontColor, 0.3);
        }
      }

      .button-rounded {
        text-align: center;
        text-decoration: none;
        padding: 11px 45px;
        align-self: center;
        margin: 0;
        font-weight: 500;
      }
    }

    .password-display-toggle {
      cursor: pointer;
    }

    .error {
      margin-top: 24px;
      color: red;
    }
  }
}

.loader {
  margin: 0 !important;
}
