@import "./variables.scss";
@import "../modules/backoffice/components/partials/DataTable/DataTable.scss";
@import "../modules/backoffice/components/partials/DropdownMenu/DropdownMenu.scss";

* {
  padding: 0;
  margin: 0;
}

body {
  font-family: "Upgrade";
  background-color: $primaryDarkerColor;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  color: $contrastFontColor;
  width: 100vw;
  height: 100vh;
}

#root {
  width: 100%;
  height: 100%;
}

h1 {
  font-size: 48px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.website-content {
  width: 100%;
  height: calc(100vh - #{$headerSize});
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

button,
input {
  background: none repeat scroll 0 0 transparent;
  border: medium none;
  border-spacing: 0;
  color: $contrastFontColor;
  font-size: 16px;
  font-weight: normal;
  line-height: 1.42rem;
  list-style: none outside none;
  margin: 0;
  padding: 0;
  text-align: left;
  text-decoration: none;
  text-indent: 0;

  &:hover {
    cursor: pointer;
  }
  &:active {
    outline: 0;
    border: 0;
  }
  &:focus {
    outline: 0 !important;
  }
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

.button-rounded {
  position: relative;
  background-color: $tertiaryColor;
  padding: 11px 24px;
  color: $secondaryColor !important;
  border-radius: 30px;
  font-weight: 500;

  &:hover {
    background-color: $tertiaryDarkColor;
  }
}

input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type="text"] {
  width: 100%;
}

.quiestce-container,
.campaignList,
.ddahQf {
  &-2::-webkit-scrollbar-track {
    border-radius: 10px;
  }
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: rgba($primaryDarkerColor, 0.1);
  }
  scrollbar-width: thin;
  scrollbar-color: rgba($primaryDarkerColor, 0.1) transparent;

  .ReactModal__Content {
    overflow: visible !important;
  }
}

.visitor-layout {
  display: flex;
  flex-direction: column;
  padding: 3rem 4rem;
  margin-right: 28px;

  h1 {
    margin-bottom: 2rem;
    font-weight: 800;
    color: $contrastFontColor;
  }
}

.muted {
  color: #b1b1b1;
}

.popup {
  background-color: $secondaryColor;
  position: absolute;
  top: -120%;

  padding: 12px 16px;
  color: $contrastFontColor;
  font-size: 16px;
  line-height: 16px;
  border-radius: 6px;
  display: flex;
  gap: 9px;
  align-items: center;
}

.rdt_TableCol_Sortable {
  color: $primaryFontColor !important;
}
